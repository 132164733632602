import {USERS_KEY, buildUserId, MUTATION_KEYS} from "../config/keys";
import {useOperationMethod} from "react-openapi-client";

export default (queryClient, queryConfig) => {

    queryClient.setMutationDefaults(MUTATION_KEYS.POST_USER, {
        mutationFn: async ({operation, payload}) =>{
            return operation(null,payload).then(({data}) => data)
        },
        onSuccess: data => {
            const allUsers = [...queryClient.getQueryData(USERS_KEY),data]
            queryClient.setQueryData(USERS_KEY,allUsers)
        },
        onError: (error, _, context) => {

        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.PUT_USER, {
        mutationFn: async ({operation,id,payload}) => {

            return operation(id, payload).then(({data}) => data)
        },
        onSuccess: (data, variables, context) => {
            const allUsers = queryClient.getQueryData(USERS_KEY)
            const mutatedData = allUsers.map((e) => {
                if(e.user_Id===data.user_Id){
                    return data
                }
                return e
            })
            queryClient.setQueryData(USERS_KEY,mutatedData)
            queryClient.setQueryData(buildUserId(variables.id),data)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
        },
    });

}