import blockchainMutations from './blockchains'
import userMutations from './users'
import userWalletsMutations from './user-wallets'
import offerMutations from './offers'
import salesMutations from './sales'
import collectionsMutations from './collections';
import contentMutations from './contents';
import favoriteCollectionMutations from './favorite-collections';
import itemsMutations from './items';
import itemBidsMutations from './item-bids';
import loginMutations from './login';

const configureMutations = (
    queryClient,
    queryConfig,
) => {
    blockchainMutations(queryClient,queryConfig)
    userMutations(queryClient,queryConfig)
    userWalletsMutations(queryClient,queryConfig)
    offerMutations(queryClient,queryConfig)
    salesMutations(queryClient,queryConfig)
    collectionsMutations(queryClient,queryConfig)
    contentMutations(queryClient,queryConfig)
    favoriteCollectionMutations(queryClient,queryConfig)
    itemBidsMutations(queryClient,queryConfig)
    itemsMutations(queryClient,queryConfig)
    salesMutations(queryClient,queryConfig)
    loginMutations(queryClient,queryConfig)
}

export default configureMutations;