import React from "react";
import { useGetAxiosConfig } from "./hooks/openapi";
import { OpenAPIProvider } from "react-openapi-client";
import config from "./config/index";

const  OpenApiWrapper = ({ children }) => {

    const axiosConf = useGetAxiosConfig();

    const ApiHost = config.ApiHost;

    return (
        <div>
            <OpenAPIProvider
                axiosConfigDefaults={axiosConf}
                definition={`${ApiHost}/swagger.json`}
            >
                {children}
            </OpenAPIProvider>
        </div>
    );
};

export default React.memo(OpenApiWrapper);