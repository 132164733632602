import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import config from "../../config/index";
import { getWithExpiry } from "../../utils/localStorage/getWithExpire";

export const useGetAxiosConfig = () => {

  const ApiHost = config.ApiHost;
  const { account } = useEthers();
  const sig = account ? getWithExpiry(`sig-${account.toLowerCase()}`) : {};

  const [currAccount, setCurrAccount] = useState("");
  const [axiosConf, setAxiosConf] = useState({
    baseURL: ApiHost,
  });

  useEffect(() => {
    if (sig) {
      if (sig !== {} && currAccount !== account) {
        setCurrAccount(account);
        setAxiosConf({
          ...axiosConf,
          headers: {
            "x-web3-auth-address": sig.address?.toLowerCase() || "",
            "x-web3-auth-msg": sig.message || "",
            "x-web3-auth-signed": sig.signature || "",
          },
        });
      }
    }
  }, [sig, account]);
  return axiosConf;
};
