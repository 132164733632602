import { ChainId } from '@usedapp/core';

const ContractsConfig = {
  [ChainId.Rinkeby]: {
    OASISXREGISTERY: process.env.NEXT_PUBLIC_OASISXREGISTRY_ADDRESS_RINKEBY,
    OASISXEXCHANGE: process.env.NEXT_PUBLIC_OASISXEXCHANGE_ADDRESS_RINKEBY,
    OASISXATOMICIZER: process.env.NEXT_PUBLIC_OASISXATOMICIZER_ADDRESS_RINKEBY,
    OASISXSTATIC: process.env.NEXT_PUBLIC_OASISXSTATIC_ADDRESS_RINKEBY,
    WETH: process.env.NEXT_PUBLIC_WETH_ADDRESS_RINKEBY,
    erc721Test:process.env.NEXT_PUBLIC_ERC721_TEST_RINKEBY
  },
  [ChainId.Mainnet]: {
    OASISXREGISTERY: process.env.NEXT_PUBLIC_OASISXREGISTRY_ADDRESS_MAINNET,
    OASISXEXCHANGE: process.env.NEXT_PUBLIC_OASISXEXCHANGE_ADDRESS_MAINNET,
    OASISXATOMICIZER: process.env.NEXT_PUBLIC_OASISXATOMICIZER_ADDRESS_MAINNET,
    OASISXSTATIC: process.env.NEXT_PUBLIC_OASISXSTATIC_ADDRESS_MAINNET,
    WETH: process.env.NEXT_PUBLIC_WETH_ADDRESS_MAINNET
  },
  [ChainId.Goerli]: {
    OASISXREGISTERY: process.env.NEXT_PUBLIC_OASISXREGISTRY_ADDRESS_GOERLI,
    OASISXEXCHANGE: process.env.NEXT_PUBLIC_OASISXEXCHANGE_ADDRESS_GOERLI,
    OASISXATOMICIZER: process.env.NEXT_PUBLIC_OASISXATOMICIZER_ADDRESS_GOERLI,
    OASISXSTATIC: process.env.NEXT_PUBLIC_OASISXSTATIC_ADDRESS_GOERLI,
    WETH: process.env.NEXT_PUBLIC_WETH_ADDRESS_GOERLI
  }
}

export default ContractsConfig;
