import { useQuery } from "react-query";
import {
  ITEMS_KEY,
  ITEMS_BY_COLLECTION_ID_KEY,
  buildItemByCollectionId,
  buildCollectionItemId,
  buildItemById,
  ITEMS_OWN_KEY,
} from "../config/keys";
import { useOperation, useOperationMethod } from "react-openapi-client";

export default (queryClient, queryConfig) => {
  const { retry, cacheTime, staleTime } = queryConfig;
  const defaultOptions = {
    retry,
    cacheTime,
    staleTime,
  };

  const useItems = ({ id }) => {
    const [GetItems] = useOperationMethod("GetItems");
    const [GetItem] = useOperationMethod("GetItem");

    return useQuery({
      queryKey: id ? buildItemByCollectionId('',id) : ITEMS_KEY,
      queryFn: async () =>
        id
          ? GetItem(id).then(({ data }) => data)
          : GetItems().then(({ data }) => data),
      onSuccess: async (items) => {
        if(items?.length>0) {
          // save items in their own key
          items.forEach(async (item) => {
            const { tokenId, collectionId, Id } = item;

            queryClient.setQueryData(
              buildCollectionItemId(collectionId, tokenId),
              item
            );
            queryClient.setQueryData(buildItemById(Id), item);
          });
        }
      },
      ...defaultOptions,
    });
  };
  const useOwnItems = ({ userId }) => {
    const [GetItems] = useOperationMethod("GetItems");

    return useQuery({
      queryKey: ITEMS_OWN_KEY,
      queryFn: async () =>
        GetItems([
          { name: "filter", value: JSON.stringify({ userId }), in: "query" },
        ]).then(({ data }) => data),
      onSuccess: async (items) => {
        if (items?.length > 0) {
          // save items in their own key
          items.forEach(async (item) => {
            const { tokenId, collectionId, Id } = item;
            queryClient.setQueryData(
              buildCollectionItemId(collectionId, tokenId),
              item
            );
            queryClient.setQueryData(buildItemById(Id), item);
          });
        }
      },
      enabled: Boolean(userId),
      ...defaultOptions,
    });
  };

  const useItemByCollectionIdAndTokenId = ({ collectionId, Id }) => {
    const [GetItems] = useOperationMethod("GetItems");

    return useQuery({
      queryKey: buildCollectionItemId(collectionId, Id),
      queryFn: async () =>
        GetItems([
          {
            name: "filter",
            value: JSON.stringify({
              tokenId: Id,
              collectionId,
            }),
            in: "query",
          },
        ]).then(({ data }) => {
          return data[0];
        }),
      enabled: Boolean(collectionId) && Boolean(Id),
      ...defaultOptions,
    });
  };

  const useItemsByCollectionId = (collectionId) => {
    const [GetItemsByCollectionId] = useOperationMethod(
      "GetItemsByCollectionId"
    );

    return useQuery({
      queryKey: collectionId
        ? buildItemsByCollectionId(collectionId)
        : ITEMS_BY_COLLECTION_ID_KEY,
      queryFn: async () =>
        collectionId
          ? GetItemsByCollectionId(collectionId).then(({ data }) => data)
          : GetItemsByCollectionId().then(({ data }) => data),
      ...defaultOptions,
    });
  };

  return {
    useItems,
    useItemByCollectionIdAndTokenId,
    useItemsByCollectionId,
    useOwnItems,
  };
};
