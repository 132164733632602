import {  useQuery } from 'react-query';
import {BLOCKCHAINS_KEY, buildBlockchainId} from "../config/keys";
import {useOperation, useOperationMethod} from "react-openapi-client";

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };

    const useBlockchains = ({enabled = false})=>{
        const [GetBlockchains]= useOperationMethod('GetBlockchains');

        return useQuery({
            queryKey: BLOCKCHAINS_KEY,
            queryFn: async () =>
                GetBlockchains().then(({data}) => data),
            enabled,
            ...defaultOptions,
        });
    }
    const useBlockchainsById = ({id, enabled = false})=>{
        const [GetBlockchain]= useOperationMethod('GetBlockchain');

        return useQuery({
            queryKey: buildBlockchainId(id),
            queryFn: async () =>
                GetBlockchain(id).then(({data}) => data),
            enabled: id? id!=='' && enabled : enabled,
            ...defaultOptions,
        });
    }
    return {
        useBlockchains,
        useBlockchainsById
    }
}
