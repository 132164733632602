import {  useQuery } from 'react-query';
import {useOperation, useOperationMethod} from "react-openapi-client";
import {buildOfferByItemId, buildOfferId, OFFERS_KEY} from "../config/keys"; //! OFFERS_KEY

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useOffers = (id) =>{
        const [GetOffers]= useOperationMethod('GetOffers');
        const [GetOffer]= useOperationMethod('GetOffer');

        return useQuery({
            queryKey: id? buildOfferId(id): OFFERS_KEY,
            queryFn: async () =>
                id? GetOffer(id).then(({data}) => data):  GetOffers().then(({data}) => data),
            ...defaultOptions,
        });
    }

    const useOffersByItemId = (itemId) =>{
        const [GetOffersByItemId]= useOperationMethod('GetOffersByItemId');

        return useQuery({
            queryKey: buildOfferByItemId(itemId),
            queryFn: async () =>
                GetOffersByItemId(itemId).then(({data}) => data),
            ...defaultOptions,
        });
    }

    return {
        useOffers,
        useOffersByItemId
    }
}
