import { CURRENT_USER_KEY, ACTIVE_KEY, MUTATION_KEYS } from '../config/keys';
import axios from 'axios';

export default (queryClient, queryConfig) => {
  queryClient.setMutationDefaults(MUTATION_KEYS.POST_LOGIN, {
    mutationFn: async ({ payload }) => {
      return axios
        .post(
          `${queryConfig.API_HOST}/login/Admin`,
          JSON.stringify({ ...payload }),
          {
            headers: {
              'Content-Type': 'application/json',
              'x-web3-auth-admin': 'true'
            }
          }
        )
        .then(({ data }) => data);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(CURRENT_USER_KEY, data);
      queryClient.setQueryData(ACTIVE_KEY, 'success');
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(ACTIVE_KEY, 'fail');
    }
  });
};
