import { Button } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { useNavigate } from 'react-router-dom';
import { setWithExpiry } from 'src/utils/localStorage/setWithExpire';

const DisconnectWalletButton = () => {
  const navigate = useNavigate();
  const { active, deactivate } = useEthers();

  return (
    <Button
      variant="contained"
      sx={{ mx: 2 }}
      onClick={() => {
        deactivate();
        setWithExpiry(`extra`, null, 43200000);
        navigate('/');
      }}
    >
      Disconnect Wallet
    </Button>
  );
};

export default DisconnectWalletButton;
