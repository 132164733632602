export const BLOCKCHAINS_KEY = "blockchain";
export const buildBlockchainId = (id) => BLOCKCHAINS_KEY + "-" + id;

export const COLLECTIONS_KEY = "collection";
export const buildCollectionId = (id) => COLLECTIONS_KEY + "-" + id;

export const FAVORITE_COLLECTIONS_KEY = "favoriteCollection";
export const buildFavoriteCollectionId = (id) =>
  FAVORITE_COLLECTIONS_KEY + "-" + id;

export const FAVORITE_COLLECTIONS_BY_USER_ID_KEY = "favoriteCollectionByUserId";
export const buildFavoriteCollectionByUserId = (userId) =>
  FAVORITE_COLLECTIONS_BY_USER_ID_KEY + "-" + userId;

export const FAVORITE_COLLECTIONS_BY_COLLECTION_ID_KEY =
  "favoriteCollectionByCollectionId";
export const buildFavoriteCollectionByCollectionId = (collectionId) =>
  FAVORITE_COLLECTIONS_BY_COLLECTION_ID_KEY + "-" + collectionId;

export const ITEMS_KEY = "items";
export const buildCollectionItemId = (collectionId,id) =>COLLECTIONS_KEY + "-" + collectionId + "-" +ITEMS_KEY + "-" + id;
export const buildItemById = (itemId) =>
    ITEMS_KEY + "-" + itemId;

export const ITEMS_BY_COLLECTION_ID_KEY = "itemsByCollectionId";
export const buildItemByCollectionId = (collectionId) =>
  ITEMS_BY_COLLECTION_ID_KEY + "-" + collectionId;
export const ITEMS_OWN_KEY =  ITEMS_KEY + "-"  + "OWN";

export const ITEM_BIDS_KEY = "itemBids";
export const buildItemBidId = (id) => ITEM_BIDS_KEY + "-" + id;

export const ITEM_BIDS_BY_ITEM_ID_KEY = "itemBidsByItemId";
export const buildItemBidByItemId = (itemId) =>
  ITEM_BIDS_BY_ITEM_ID_KEY + "-" + itemId;

export const ITEM_BIDS_BY_OFFER_ID_KEY = "itemBidsByOfferId";
export const buildItemBidByOfferId = (offerId) =>
  ITEM_BIDS_BY_OFFER_ID_KEY + "-" + offerId;

export const CONTENTS_KEY = "content";
export const buildContentId = (id) => CONTENTS_KEY + "-" + id;

export const OFFERS_KEY = "offer";
export const buildOfferId = (id) => OFFERS_KEY + "-" + id;
export const buildOfferByItemId = (id) => OFFERS_KEY + "-by-user-" + id;

export const USERS_KEY = "user";
export const buildUserId = (id) => USERS_KEY + "-" + id;

export const USER_WALLETS_KEY = "user-wallet";
export const buildUserWalletId = (id) => USER_WALLETS_KEY + "-" + id;

export const SALES_KEY = "user-wallet";
export const buildSalesId = (id) => SALES_KEY + "-" + id;
export const buildSaleByItemId = (id) => SALES_KEY + "-by-user-" + id;

export const CURRENT_USER_KEY = "current-user"
export const ACTIVE_KEY = "active"

export const MUTATION_KEYS = {
  POST_BLOCKCHAIN: "postBlockchains",
  DELETE_BLOCKCHAIN: "deleteBlockchain",
  PUT_BLOCKCHAIN: "putBlockchain",
  POST_COLLECTION: "postCollections",
  DELETE_COLLECTION: "deleteCollection",
  PUT_COLLECTION: "putCollection",
  POST_FAVORITECOLLECTION: "postFavoriteCollections",
  DELETE_FAVORITECOLLECTION: "deleteFavoriteCollection",
  POST_ITEM: "postItems",
  DELETE_ITEM: "deleteItem",
  PUT_ITEM: "putItem",
  POST_ITEMBID: "postItems",
  DELETE_ITEMBID: "deleteItem",
  PUT_ITEMBID: "putItem",
  POST_CONTENT: "postContents",
  DELETE_CONTENT: "deleteContent",
  PUT_CONTENT: "putContent",
  POST_OFFER: "postOffer",
  DELETE_OFFER: "deleteOffer",
  PUT_OFFER: "putOffer",
  POST_USER: "postUser",
  PUT_USER: "putUser",
  POST_USER_WALLET: "postUserWallet",
  DELETE_USER_WALLET: "deleteUserWallet",
  PUT_USER_WALLET: "putUserWallet",
  POST_SALE: "postSale",
  DELETE_SALE: "deleteSale",
  PUT_SALE: "putSale",
  POST_LOGIN:'login'
};
