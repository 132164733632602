import { getChainById, ChainId } from '@usedapp/core';

const stage = process.env.NEXT_PUBLIC_ENV;

const DappConfig = {
  readOnlyChainId:
    stage === 'development' || stage === 'stagingR'
      ? ChainId.Rinkeby
      : stage === 'staging'
      ? ChainId.Goerli
      : ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
    [ChainId.Goerli]: `https://goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`
  },
  pollingInterval: 1000,
  networks: [ getChainById(ChainId.Mainnet),getChainById(ChainId.Goerli)],
  notifications: {
    checkInterval: 100,
    expirationPeriod: 10000
  },
  autoConnect: true
};

export default DappConfig;
