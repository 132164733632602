import { Box, Hidden } from '@mui/material';

function Logo() {
  return (
    <>
      <Hidden smDown>
        <Box
          component="img"
       
          sx={{
            
            width: 100,
          m:2,
          }}
          alt="Oasisx"
          src="/static/images/overview/oasisx-logo-beta.png"
        />
      </Hidden>
      <Hidden smUp>
        <Box
          component="img"
          sx={{
            height: 130,
            width: 40,
            maxHeight: { xs: 130, md: 130 },
            maxWidth: { xs: 40, md: 40 }
          }}
          alt="Oasisx"
          src="/static/images/overview/oasisx-logo-only.svg"
        />
      </Hidden>
    </>
  );
}

export default Logo;
