import {  useQuery } from 'react-query';
import {COLLECTIONS_KEY, buildCollectionId} from "../config/keys";
import {useOperation, useOperationMethod} from "react-openapi-client";

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useCollections = ({id}) =>{
        const [GetCollections]= useOperationMethod('GetCollections');
        return useQuery({
            queryKey: id? buildCollectionId(id): COLLECTIONS_KEY,
            queryFn: async () =>
               id?
               GetCollections([{ name: 'filter', value: JSON.stringify({Id:id}), in: 'query' }]).then(({data}) => data[0]):
                 GetCollections().then(({data}) => data),
             onSuccess: async (collections) => {
                     if (collections?.length>0) {
                       collections.forEach(async (collection) => {
                         const { Id} = collection;
                         queryClient.setQueryData(buildCollectionId(Id),collection);
                       });
                     }
                   },
            ...defaultOptions,
        });
    }
    return {
        useCollections
    }
}
