import {  useQuery } from 'react-query';
import {FAVORITE_COLLECTIONS_KEY, FAVORITE_COLLECTIONS_BY_USER_ID_KEY, FAVORITE_COLLECTIONS_BY_COLLECTION_ID_KEY, buildFavoriteCollectionId,  buildFavoriteCollectionByUserId, buildFavoriteCollectionByCollectionId} from "../config/keys";
import {useOperation, useOperationMethod} from "react-openapi-client";

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useFavoriteCollections = (id) =>{
        const [GetFavoriteCollections]= useOperationMethod('GetFavoriteCollections');
        const [GetFavoriteCollection]= useOperationMethod('GetFavoriteCollection');

        return useQuery({
            queryKey: id? buildFavoriteCollectionId(id): FAVORITE_COLLECTIONS_KEY,
            queryFn: async () =>
               id? GetFavoriteCollection(id).then(({data}) => data):  GetFavoriteCollections().then(({data}) => data),
            ...defaultOptions,
        });
    }

    
    const useFavoriteCollectionsByUserId = (userId) =>{
        const [GetFavoriteCollectionByUserId]= useOperationMethod('GetFavoriteCollectionByUserId');

        return useQuery({
            queryKey: userId? buildFavoriteCollectionByUserId(userId): FAVORITE_COLLECTIONS_BY_USER_ID_KEY,
            queryFn: async () =>
               userId? GetFavoriteCollectionByUserId(userId).then(({data}) => data):  GetFavoriteCollectionsByUserId().then(({data}) => data),
            ...defaultOptions,
        });
    }

    const useFavoriteCollectionsByCollectionId = (collectionId) =>{
        const [GetFavoriteCollectionByCollectionId]= useOperationMethod('GetFavoriteCollectionByCollectionId');

        return useQuery({
            queryKey: collectionId? buildFavoriteCollectionByCollectionId(collectionId): FAVORITE_COLLECTIONS_BY_COLLECTION_ID_KEY,
            queryFn: async () =>
               collectionId? GetFavoriteCollectionByCollectionId(collectionId).then(({data}) => data):  GetFavoriteCollectionsByCollectionId().then(({data}) => data),
            ...defaultOptions,
        });
    }

    return {
        useFavoriteCollections,
        useFavoriteCollectionsByUserId,
        useFavoriteCollectionsByCollectionId
    }
}
