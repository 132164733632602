import configureBlockchainsHooks from './blockchains';
import configureOffersHooks from './offers';
import configureUserWalletsHooks from './user-wallets';
import configureUsersHooks from './users';
import configureSalesHooks from './sales';
import configureCollectionsHooks from './collections';
import configureContentsHooks from './contents';
import configureFavoriteCollectionsHooks from './favorite-collections';
import configureItemsHooks from './items';
import configureItemBidsHooks from './item-bids';

export default (
    queryClient,
    queryConfig,
    token
) => {
    return {
        ...configureBlockchainsHooks(queryClient,queryConfig,token),
        ...configureUsersHooks(queryClient,queryConfig,token),
        ...configureOffersHooks(queryClient,queryConfig,token),
        ...configureUserWalletsHooks(queryClient,queryConfig,token),
        ...configureSalesHooks(queryClient,queryConfig,token),
        ...configureCollectionsHooks(queryClient,queryConfig,token),
        ...configureContentsHooks(queryClient,queryConfig,token),
        ...configureFavoriteCollectionsHooks(queryClient,queryConfig,token),
        ...configureItemsHooks(queryClient,queryConfig,token),
        ...configureItemBidsHooks(queryClient,queryConfig,token),
    }
}