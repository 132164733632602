import {  useQuery } from 'react-query';
import {USER_WALLETS_KEY, buildUserWalletId} from "../config/keys";
import {useOperation, useOperationMethod} from "react-openapi-client";

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useUserWallets = (id) =>{
        const [GetUsersWallets]= useOperationMethod('GetUsersWallets');
        const [GetUserWallet]= useOperationMethod('GetUserWallet');

        return useQuery({
            queryKey: id? buildUserWalletId(id): USER_WALLETS_KEY,
            queryFn: async () =>
                id? GetUserWallet(id).then(({data}) => data):  GetUsersWallets().then(({data}) => data),
            ...defaultOptions,
        });
    }
    return {
        useUserWallets
    }
}
