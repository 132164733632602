import {  useQuery } from 'react-query';
import {useOperation, useOperationMethod} from "react-openapi-client";
import {buildSaleByItemId, buildSalesId, OFFERS_KEY} from "../config/keys"; // ! OFFERS_KEY -- buildSalesId

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useSales = (id) =>{
        const [GetSales]= useOperationMethod('GetSales');
        const [GetSale]= useOperationMethod('GetSale');

        return useQuery({
            queryKey: id? buildSalesId(id): OFFERS_KEY,
            queryFn: async () =>
                id? GetSale(id).then(({data}) => data):  GetSales().then(({data}) => data),
            ...defaultOptions,
        });
    }

    const useSalesByItemId = (itemId) =>{
        const [GetSalesByItemId]= useOperationMethod('GetSalesByItemId');

        return useQuery({
            queryKey: buildSaleByItemId(itemId),
            queryFn: async () =>
                GetSalesByItemId(itemId).then(({data}) => data),
            ...defaultOptions,
        });
    }

    return {
        useSales,
        useSalesByItemId
    }
}
