import { useQuery } from 'react-query';
import {
  USERS_KEY,
  buildUserId,
  CURRENT_USER_KEY,
  ACTIVE_KEY
} from '../config/keys';
import { useOperation, useOperationMethod } from 'react-openapi-client';

export default (queryClient, queryConfig) => {
  const { retry, cacheTime, staleTime } = queryConfig;
  const defaultOptions = {
    retry,
    cacheTime,
    staleTime
  };

  const useUsers = (id) => {
    const [GetUsers] = useOperationMethod('GetUsers');
    const [GetUser] = useOperationMethod('GetUser');

    return useQuery({
      queryKey: id ? buildUserId(id) : USERS_KEY,
      queryFn: async () =>
        id
          ? GetUser(id).then(({ data }) => data)
          : GetUsers().then(({ data }) => data),
      ...defaultOptions
    });
  };

  const useCurrentUser = () =>
    useQuery({
      queryKey: CURRENT_USER_KEY
    });

  const useActive = () =>
    useQuery({
      queryKey: ACTIVE_KEY
    });

  return {
    useUsers,
    useCurrentUser,
    useActive
  };
};
