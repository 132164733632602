require('dotenv').config();

import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';

import { queryClient, QueryClientProvider } from '../src/query';
import { ReactQueryDevtools } from 'react-query/devtools';
import OpenApiWrapper from './openapi';

import { DAppProvider } from '@usedapp/core';
import config from './config/index';

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <HashRouter>
        <DAppProvider config={config.DAppProvider}>
          <QueryClientProvider client={queryClient}>
            <OpenApiWrapper>
              <ReactQueryDevtools initialIsOpen={false} />
              <App />
            </OpenApiWrapper>
          </QueryClientProvider>
        </DAppProvider>
      </HashRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
