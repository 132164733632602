import {CONTENTS_KEY, buildContentId, MUTATION_KEYS} from "../config/keys";
import {useOperationMethod} from "react-openapi-client";

export default (queryClient, queryConfig) => {

    queryClient.setMutationDefaults(MUTATION_KEYS.POST_CONTENT, {
        mutationFn: async ({operation, payload}) =>{
            return operation(null,payload).then(({data}) => data)
        },
        onSuccess: data => {
            const allContents = [...queryClient.getQueryData(CONTENTS_KEY),data]
            queryClient.setQueryData(CONTENTS_KEY,allContents)
        },
        onError: (error, _, context) => {

        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.PUT_CONTENT, {
        mutationFn: async ({operation,id,payload}) => {

            return operation(id, payload).then(({data}) => data)
        },
        onSuccess: (data, variables, context) => {
            const allContents = queryClient.getQueryData(CONTENTS_KEY)
            const mutatedData = allContents.map((e) => {
                if(e.chain_Id===data.chain_Id){
                    return data
                }
                return e
            })
            queryClient.setQueryData(CONTENTS_KEY,mutatedData)
            queryClient.setQueryData(buildContentId(variables.id),data)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
            // queryClient.invalidateQueries(buildContentId(variables.id));
        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.DELETE_CONTENT, {
        mutationFn: async ({operation,id}) => {
            operation(id).then(({data}) => data).catch(console.log)
        },
        onSuccess: (data, variables, context)=> {
            const allContents = queryClient.getQueryData(CONTENTS_KEY)
            const mutatedData = allContents.map((e) => {
                if(e.chain_Id!==data.chain_Id){
                    return e
                }
            })
            queryClient.setQueryData(CONTENTS_KEY,mutatedData)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
        },
    });


}