import {
    ITEMS_KEY,
    buildItemId,
    MUTATION_KEYS,
    buildItemByCollectionId,
    buildItemById,
    buildCollectionItemId
} from "../config/keys";
import {useOperationMethod} from "react-openapi-client";
import {getWithExpiry} from "../../utils/localStorage/getWithExpire";


export default (queryClient, queryConfig) => {

    queryClient.setMutationDefaults(MUTATION_KEYS.POST_ITEM, {
        mutationFn: async ({operation, payload, axiosConfig}) =>{
            return operation(null, payload, axiosConfig).then(({data}) => data)
        },
        onSuccess: data => {
            const allItems = [...queryClient.getQueryData(ITEMS_KEY),data]
            queryClient.setQueryData(ITEMS_KEY,allItems)
        },
        onError: (error, _, context) => {
            console.log(error)
        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.PUT_ITEM, {
        mutationFn: async ({operation,id,payload,axiosConfig}) => {
            console.log(operation,id,payload,axiosConfig)
            return operation(id, payload, axiosConfig).then((data) => data)
        },
        onSuccess: (data, variables, context) => {
            // console.log(buildItemByCollectionId(data.data.collectionId,data.data.tokenId),data.data)
            // queryClient.setQueryData(buildItemById(data.data.Id),data.data)
            console.log(data,data.collectionId,data.tokenId)
            if(data){
                console.log(data,data.data.collectionId,data.data.tokenId)
                queryClient.setQueryData(buildCollectionItemId(data.data.collectionId,data.data.tokenId),data.data)
            }
        },
        onError: (error, _, context) => {


        },
        onSettled: (data, error, variables, context) => {
            // queryClient.invalidateQueries(buildItemId(variables.id));
        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.DELETE_ITEM, {
        mutationFn: async ({operation,id}) => {
            operation(id).then(({data}) => data).catch(console.log)
        },
        onSuccess: (data, variables, context)=> {
            const allItems = queryClient.getQueryData(ITEMS_KEY)
            const mutatedData = allItems.map((e) => {
                if(e.chain_Id!==data.chain_Id){
                    return e
                }
            })
            queryClient.setQueryData(ITEMS_KEY,mutatedData)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
        },
    });


}
