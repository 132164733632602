import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Landing = Loader(lazy(() => import('src/content/overview')));

// Admin Section
const AdminUsers = Loader(
  lazy(() => import('src/content/AdminSection/AdminUsers'))
);
const CollectionListing = Loader(
  lazy(() => import('src/content/AdminSection/CollectionListing'))
);
const EditCollection = Loader(
  lazy(() => import('src/content/AdminSection/EditCollection'))
);
const ArtistVetting = Loader(
  lazy(() => import('src/content/AdminSection/ArtistVetting'))
);
const Galleries = Loader(
  lazy(() => import('src/content/AdminSection/Galleries'))
);
const CrossmintSetup = Loader(
  lazy(() => import('src/content/AdminSection/CrossmintSetup'))
);

// Website Content
const AboutUs = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/AboutUs'))
);
const DidYouKnow = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/DidYouKnow'))
);
const Xperience = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Xperience'))
);
const Partners = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Partnerships'))
);
const XclusiveBrands = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/XclusiveBrands'))
);
const Hero = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Hero'))
);
const Drops = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Drops'))
);
const Trending = Loader(
  lazy(() =>
    import('src/content/WebsiteContent/LandingPage/TrendingCollections')
  )
);
const Artists = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Artists'))
);
const Footer = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Footer'))
);
const Rankings = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Rankings'))
);
const CollectionCategories = Loader(
  lazy(() =>
    import('src/content/WebsiteContent/Categories/CollectionCategories')
  )
);
const BlogCategories = Loader(
  lazy(() => import('src/content/WebsiteContent/Categories/BlogCategories'))
);
const Blogs = Loader(lazy(() => import('src/content/WebsiteContent/Blogs')));
const LaunchPadHero = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/Hero'))
);
const LaunchPadSteps = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/Steps'))
);
const LaunchPadDemo = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/DemoBooking'))
);
const LaunchPadTips = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/TipsTricks'))
);
const LaunchPadActivations = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/Activations'))
);
const LaunchPadCreativeStudio = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchPad/CreativeStudio'))
);
const LaunchXLayers = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchXForm/Layers'))
);
const LaunchXProperties = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchXForm/Properties'))
);
const LaunchXSetupMint = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchXForm/SetupMint'))
);
const LaunchXIndex = Loader(
  lazy(() => import('src/content/WebsiteContent/LaunchXForm/Index'))
);
const CreateLandingPage = Loader(
  lazy(() => import('src/content/WebsiteContent/Create/LandingPage'))
);
const CreateStorefrontForm = Loader(
  lazy(() => import('src/content/WebsiteContent/Create/StorefrontForm'))
);
const NotificationsBanner = Loader(
  lazy(() => import('src/content/WebsiteContent/NotificationsBanner'))
);
const Localization = Loader(
  lazy(() => import('src/content/WebsiteContent/Localization'))
);
const FAQ = Loader(lazy(() => import('src/content/WebsiteContent/FAQ')));

const Documentation = Loader(
  lazy(() => import('src/content/WebsiteContent/Documentation'))
);
const DocumentationDetails = Loader(
  lazy(() => import('src/content/WebsiteContent/DocumentationDetails'))
);
const CollectionList = Loader(
  lazy(() => import('src/content/WebsiteContent/LandingPage/Collections/collectionList'))
);

const routes = [
  {
    path: '*',
    element: <Landing />,
    children: [
      {
        path: '',
        element: <Landing />
      },
      {
        path: 'admin',
        element: <Navigate to="" replace />
      },
      {
        path: 'dashboard',
        element: <Navigate to="" replace />
      }
    ]
  },
  {
    path: 'adminsection',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/adminsection/adminusers" replace />
      },
      {
        path: 'adminusers',
        element: <AdminUsers />
      },
      {
        path: 'collectionlisting',
        element: <CollectionListing />
      },
      {
        path: 'editcollection',
        element: <EditCollection />
      },
      {
        path: 'artistvetting',
        element: <ArtistVetting />
      },
      {
        path: 'galleries',
        element: <Galleries />
      },
      {
        path: 'crossmintsetup',
        element: <CrossmintSetup />
      }
    ]
  },
  {
    path: 'websitecontent',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/websitecontent/landingpage/hero" replace />
      },
      {
        path: 'landingpage',
        element: <Navigate to="/websitecontent/landingpage/hero" replace />
      },
      {
        path: 'landingpage/hero',
        element: <Hero />
      },
      {
        path: 'landingpage/Xperience',
        element: <Xperience />
      },
      {
        path: 'landingpage/didyouknow',
        element: <DidYouKnow />
      },
      {
        path: 'landingpage/aboutus',
        element: <AboutUs />
      },
      {
        path: 'landingpage/partners',
        element: <Partners />
      },
      {
        path: 'landingpage/xclusivebrands',
        element: <XclusiveBrands />
      },
      {
        path: 'landingpage/xceptionaldrops',
        element: <Drops />
      },
      {
        path: 'landingpage/notableartists',
        element: <Artists />
      },
      {
        path: 'landingpage/trending',
        element: <Trending />
      },
      {
        path: 'landingpage/footer',
        element: <Footer />
      },
      {
        path: 'landingpage/rankings',
        element: <Rankings />
      },
      {
        path: 'launchpad',
        element: <Navigate to="/websitecontent/launchpad/hero" replace />
      },
      {
        path: 'launchpad/hero',
        element: <LaunchPadHero />
      },
      {
        path: 'launchpad/steps',
        element: <LaunchPadSteps />
      },
      {
        path: 'launchpad/demobooking',
        element: <LaunchPadDemo />
      },
      {
        path: 'launchpad/tipsandtricks',
        element: <LaunchPadTips />
      },
      {
        path: 'launchpad/activations',
        element: <LaunchPadActivations />
      },
      {
        path: 'launchpad/creativestudio',
        element: <LaunchPadCreativeStudio />
      },
      {
        path: 'launchxform',
        element: <Navigate to="/websitecontent/launchxform/layers" replace />
      },
      {
        path: 'launchxform/index',
        element: <LaunchXIndex />
      },
      {
        path: 'launchxform/layers',
        element: <LaunchXLayers />
      },
      {
        path: 'launchxform/properties',
        element: <LaunchXProperties />
      },
      {
        path: 'launchxform/setupmint',
        element: <LaunchXSetupMint />
      },
      {
        path: 'create',
        element: <Navigate to="/websitecontent/create/landingpage" replace />
      },
      {
        path: 'create/landingpage',
        element: <CreateLandingPage />
      },
      {
        path: 'create/storefrontform',
        element: <CreateStorefrontForm />
      },
      {
        path: 'blogs',
        element: <Blogs />
      },
      {
        path: 'notificationsbanner',
        element: <NotificationsBanner />
      },
      {
        path: 'faq',
        element: <FAQ />
      },
      {
        path: 'documentation',
        element: <Documentation />
      },
      {
        path: 'documentation/:id',
        element: <DocumentationDetails />
      },
      {
        path: 'localization',
        element: <Localization />
      },
      {
        path: 'categories',
        element: <CollectionCategories />
      },
      {
        path: 'categories/collections',
        element: <CollectionCategories />
      },
      {
        path: 'categories/blogs',
        element: <BlogCategories />
      },
      {
        path: 'collections',
        element: <CollectionList />
      },
    ]
  }
];

export default routes;
