import { useQuery } from "react-query";
import {
  ITEM_BIDS_KEY,
  ITEM_BIDS_BY_ITEM_ID_KEY,
  ITEM_BIDS_BY_OFFER_ID_KEY,
  buildItemBidId,
  buildItemBidByItemId,
  buildItemBidByOfferId,
} from "../config/keys";
import { useOperation, useOperationMethod } from "react-openapi-client";

export default (queryClient, queryConfig) => {
  const { retry, cacheTime, staleTime } = queryConfig;
  const defaultOptions = {
    retry,
    cacheTime,
    staleTime,
  };

  const useItemBids = (id) => {
    const [GetItemBids] = useOperationMethod("GetItemBids");
    const [GetItemBid] = useOperationMethod("GetItemBid");

    return useQuery({
      queryKey: id ? buildItemBidId(id) : ITEM_BIDS_KEY,
      queryFn: async () =>
        id
          ? GetItemBid(id).then(({ data }) => data)
          : GetItemBids().then(({ data }) => data),
      ...defaultOptions,
    });
  };

  const useItemBidsByItemId = (itemId) => {
    const [GetItemBidByItemId] = useOperationMethod("GetItemBidByItemId");

    return useQuery({
      queryKey: itemId
        ? buildItemBidByItemId(itemId)
        : ITEM_BIDS_BY_ITEM_ID_KEY,
      queryFn: async () =>
        itemId
          ? GetItemBidByItemId(itemId).then(({ data }) => data)
          : GetItemBidByItemId().then(({ data }) => data),
      ...defaultOptions,
    });
  };

  const useItemBidsByOfferId = (offerId) => {
    const [GetItemBidByOfferId] = useOperationMethod("GetItemBidByOfferId");

    return useQuery({
      queryKey: offerId
        ? buildItemBidByOfferId(offerId)
        : ITEM_BIDS_BY_OFFER_ID_KEY,
      queryFn: async () =>
        offerId
          ? GetItemBidByOfferId(offerId).then(({ data }) => data)
          : GetItemBidByOfferId().then(({ data }) => data),
      ...defaultOptions,
    });
  };
  return {
    useItemBids,
    useItemBidsByItemId,
    useItemBidsByOfferId,
  };
};
