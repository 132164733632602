import {OFFERS_KEY, buildBlockchainId, MUTATION_KEYS} from "../config/keys";

export default (queryClient, queryConfig) => {

    queryClient.setMutationDefaults(MUTATION_KEYS.POST_OFFER, {
        mutationFn: async ({operation, payload}) =>{
            return operation(null,payload).then(({data}) => data)
        },
        onSuccess: data => {
            const allOffers = [...queryClient.getQueryData(OFFERS_KEY),data]
            queryClient.setQueryData(OFFERS_KEY,allOffers)
        },
        onError: (error, _, context) => {

        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.PUT_OFFER, {
        mutationFn: async ({operation,id,payload}) => {

            return operation(id, payload).then(({data}) => data)
        },
        onSuccess: (data, variables, context) => {
            const allOffers = queryClient.getQueryData(OFFERS_KEY)
            const mutatedData = allOffers.map((e) => {
                if(e.offer_Id===data.offer_Id){
                    return data
                }
                return e
            })
            queryClient.setQueryData(OFFERS_KEY,mutatedData)
            queryClient.setQueryData(buildBlockchainId(variables.id),data)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
            // queryClient.invalidateQueries(buildBlockchainId(variables.id));
        },
    });
    queryClient.setMutationDefaults(MUTATION_KEYS.DELETE_OFFER, {
        mutationFn: async ({operation,id}) => {
            operation(id).then(({data}) => data).catch(console.log)
        },
        onSuccess: (data, variables, context)=> {
            const allOffers = queryClient.getQueryData(OFFERS_KEY)
            const mutatedData = allOffers.map((e) => {
                if(e.offer_Id!==data.offer_Id){
                    return e
                }
            })
            queryClient.setQueryData(OFFERS_KEY,mutatedData)
        },
        onError: (error, _, context) => {

        },
        onSettled: (data, error, variables, context) => {
        },
    });


}