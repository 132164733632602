import {
  FAVORITE_COLLECTIONS_KEY,
  buildFavoriteCollectionId,
  MUTATION_KEYS,
} from "../config/keys";
import { useOperationMethod } from "react-openapi-client";

export default (queryClient, queryConfig) => {
  queryClient.setMutationDefaults(MUTATION_KEYS.POST_FAVORITECOLLECTION, {
    mutationFn: async ({ operation, payload }) => {
      return operation(null, payload).then(({ data }) => data);
    },
    onSuccess: (data) => {
      const allFavoriteCollections = [
        ...queryClient.getQueryData(FAVORITECOLLECTIONS_KEY),
        data,
      ];
      queryClient.setQueryData(FAVORITECOLLECTIONS_KEY, allFavoriteCollections);
    },
    onError: (error, _, context) => {},
  });

  queryClient.setMutationDefaults(MUTATION_KEYS.DELETE_FAVORITECOLLECTION, {
    mutationFn: async ({ operation, id }) => {
      operation(id)
        .then(({ data }) => data)
        .catch(console.log);
    },
    onSuccess: (data, variables, context) => {
      const allFavoriteCollections = queryClient.getQueryData(
        FAVORITECOLLECTIONS_KEY
      );
      const mutatedData = allFavoriteCollections.map((e) => {
        if (e.collection_Id !== data.chain_Id) {
          return e;
        }
      });
      queryClient.setQueryData(FAVORITECOLLECTIONS_KEY, mutatedData);
    },
    onError: (error, _, context) => {},
    onSettled: (data, error, variables, context) => {},
  });
};
