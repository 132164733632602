import {
  COLLECTIONS_KEY,
  buildCollectionId,
  MUTATION_KEYS,
} from "../config/keys";
import { useOperationMethod } from "react-openapi-client";

export default (queryClient, queryConfig) => {
  queryClient.setMutationDefaults(MUTATION_KEYS.POST_COLLECTION, {
    mutationFn: async ({ operation, payload }) => {
      return operation(null, payload).then(({ data }) => data);
    },
    onSuccess: (data) => {
      const allCollections = [
        ...queryClient.getQueryData(COLLECTIONS_KEY),
        data,
      ];
      queryClient.setQueryData(COLLECTIONS_KEY, allCollections);
    },
    onError: (error, _, context) => {},
  });
  queryClient.setMutationDefaults(MUTATION_KEYS.PUT_COLLECTION, {
    mutationFn: async ({ operation, id, payload }) => {
      return operation(id, payload).then(({ data }) => data);
    },
    onSuccess: (data, variables, context) => {
      const allCollections = queryClient.getQueryData(COLLECTIONS_KEY);
      const mutatedData = allCollections.map((e) => {
        if (e.collection_Id === data.collection_Id) {
          return data;
        }
        return e;
      });
      queryClient.setQueryData(COLLECTIONS_KEY, mutatedData);
      queryClient.setQueryData(buildCollectionId(variables.id), data);
    },
    onError: (error, _, context) => {},
    onSettled: (data, error, variables, context) => {
      // queryClient.invalidateQueries(buildCollectionId(variables.id));
    },
  });
  queryClient.setMutationDefaults(MUTATION_KEYS.DELETE_COLLECTION, {
    mutationFn: async ({ operation, id }) => {
      operation(id)
        .then(({ data }) => data)
        .catch(console.log);
    },
    onSuccess: (data, variables, context) => {
      const allCollections = queryClient.getQueryData(COLLECTIONS_KEY);
      const mutatedData = allCollections.map((e) => {
        if (e.collection_Id !== data.collection_Id) {
          return e;
        }
      });
      queryClient.setQueryData(COLLECTIONS_KEY, mutatedData);
    },
    onError: (error, _, context) => {},
    onSettled: (data, error, variables, context) => {},
  });
};
