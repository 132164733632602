import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import BubbleChartTwoToneIcon from '@mui/icons-material/BubbleChartTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone';
import SmartDisplayTwoToneIcon from '@mui/icons-material/SmartDisplayTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import GTranslateTwoToneIcon from '@mui/icons-material/GTranslateTwoTone';
import LiveHelpTwoToneIcon from '@mui/icons-material/LiveHelpTwoTone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';

const menuItems = [
  {
    heading: 'Admin Section',
    allowed: [
      '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      '048c14fb-02f9-4b4e-987f-70998915f59f'
    ],
    items: [
      {
        name: 'Admin Users',
        icon: PeopleAltTwoToneIcon,
        link: '/adminsection/adminusers',
        allowed: ['46877e4f-b2a7-47df-a541-2cfbfea4f169']
      },
      {
        name: 'Collection Listing',
        icon: ListTwoToneIcon,
        link: '/adminsection/collectionlisting',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f'
        ]
      },
      {
        name: 'Edit Collection',
        icon: CreateTwoToneIcon,
        link: '/adminsection/editcollection',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f'
        ]
      },
      {
        name: 'Artist Vetting',
        icon: BubbleChartTwoToneIcon,
        link: '/adminsection/artistvetting',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f'
        ]
      },
      {
        name: 'Galleries',
        icon: CollectionsTwoToneIcon,
        link: '/adminsection/galleries',
        allowed: ['46877e4f-b2a7-47df-a541-2cfbfea4f169']
      },
      {
        name: 'Crossmint Setup',
        icon: SettingsTwoToneIcon,
        link: '/adminsection/crossmintsetup',
        allowed: ['46877e4f-b2a7-47df-a541-2cfbfea4f169']
      }
    ]
  },
  {
    heading: 'Website Content',
    allowed: [
      '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      '048c14fb-02f9-4b4e-987f-70998915f59f',
      'a0b45702-3bbf-4851-b155-990bcb304b51'
    ],
    items: [
      {
        name: 'Collection Listing for subsidy',
        icon: AppsTwoToneIcon,
        link: '/websitecontent/collections',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Landing Page',
        icon: LanguageTwoToneIcon,
        link: '/websitecontent/landingpage',
        items: [
          {
            name: 'Hero',
            link: '/websitecontent/landingpage/hero',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Xperience',
            link: '/websitecontent/landingpage/Xperience',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Did You Know',
            link: '/websitecontent/landingpage/didyouknow',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Xceptional Drops',
            link: '/websitecontent/landingpage/xceptionaldrops',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Trending Collections',
            link: '/websitecontent/landingpage/trending',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Notable Artists',
            link: '/websitecontent/landingpage/notableartists',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'About Us',
            link: '/websitecontent/landingpage/aboutus',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Partners',
            link: '/websitecontent/landingpage/partners',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Xclusive Brands',
            link: '/websitecontent/landingpage/xclusivebrands',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Footer',
            link: '/websitecontent/landingpage/footer',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Rankings',
            link: '/websitecontent/landingpage/rankings',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          }
        ],
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Launch Pad',
        icon: SmartDisplayTwoToneIcon,
        link: '/websitecontent/launchpad',
        items: [
          {
            name: 'Hero',
            link: '/websitecontent/launchpad/hero',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Steps',
            link: '/websitecontent/launchpad/steps',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Creative Studio',
            link: '/websitecontent/launchpad/creativestudio',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Activations',
            link: '/websitecontent/launchpad/activations',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Demo Booking',
            link: '/websitecontent/launchpad/demobooking',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Tips & Tricks',
            link: '/websitecontent/launchpad/tipsandtricks',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          }
        ],
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'LaunchX Form',
        icon: CancelTwoToneIcon,
        link: '/websitecontent/launchxform',
        items: [
          {
            name: 'Index',
            link: '/websitecontent/launchxform/index',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Layers',
            link: '/websitecontent/launchxform/layers',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Properties',
            link: '/websitecontent/launchxform/properties',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Setup Mint',
            link: '/websitecontent/launchxform/setupmint',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          }
        ],
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Create',
        icon: HandymanTwoToneIcon,
        link: '/websitecontent/create',
        items: [
          {
            name: 'Landing Page',
            link: '/websitecontent/create/landingpage',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Storefront Form',
            link: '/websitecontent/create/storefrontform',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          }
        ],
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Notifications Banner',
        icon: NotificationsActiveTwoToneIcon,
        link: '/websitecontent/notificationsbanner',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'FAQ',
        icon: LiveHelpTwoToneIcon,
        link: '/websitecontent/faq',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Documentation',
        icon: InsertDriveFileIcon,
        link: '/websitecontent/documentation',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Collection Categories',
        icon: AppsTwoToneIcon,
        link: '/websitecontent/categories/collections',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      {
        name: 'Blog',
        icon: ArticleTwoToneIcon,
        link: '/websitecontent/blogs',
        items: [
          {
            name: 'Blogs',
            link: '/websitecontent/blogs',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          },
          {
            name: 'Blog Categories',
            link: '/websitecontent/categories/blogs',
            allowed: [
              '46877e4f-b2a7-47df-a541-2cfbfea4f169',
              '048c14fb-02f9-4b4e-987f-70998915f59f',
              'a0b45702-3bbf-4851-b155-990bcb304b51'
            ]
          }
        ],
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      },
      // {
      //   name: 'Categories',
      //   icon: BrightnessLowTwoToneIcon,
      //   items: [
      //     {
      //       name: 'Collection Categories',
      //       link: '/websitecontent/categories/collections',
      //       allowed: [
      //         '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      //         '048c14fb-02f9-4b4e-987f-70998915f59f',
      //         'a0b45702-3bbf-4851-b155-990bcb304b51'
      //       ]
      //     },
      //     {
      //       name: 'Blog Categories',
      //       link: '/websitecontent/categories/blogs',
      //       allowed: [
      //         '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      //         '048c14fb-02f9-4b4e-987f-70998915f59f',
      //         'a0b45702-3bbf-4851-b155-990bcb304b51'
      //       ]
      //     }
      //   ],
      //   allowed: [
      //     '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      //     '048c14fb-02f9-4b4e-987f-70998915f59f',
      //     'a0b45702-3bbf-4851-b155-990bcb304b51'
      //   ]
      // },
      // {
      //   name: 'Blogs',
      //   link: '/websitecontent/blogs',
      //   icon: CreateTwoToneIcon,
      //   allowed: [
      //     '46877e4f-b2a7-47df-a541-2cfbfea4f169',
      //     '048c14fb-02f9-4b4e-987f-70998915f59f',
      //     'a0b45702-3bbf-4851-b155-990bcb304b51'
      //   ]
      // },
      {
        name: 'Localization',
        icon: GTranslateTwoToneIcon,
        link: '/websitecontent/localization',
        allowed: [
          '46877e4f-b2a7-47df-a541-2cfbfea4f169',
          '048c14fb-02f9-4b4e-987f-70998915f59f',
          'a0b45702-3bbf-4851-b155-990bcb304b51'
        ]
      }
    ]
  }
];

export default menuItems;
