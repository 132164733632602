import {  useQuery } from 'react-query';
import {CONTENTS_KEY, buildContentId} from "../config/keys";
import {useOperation, useOperationMethod} from "react-openapi-client";

export default (
    queryClient,
    queryConfig,
) => {
    const {retry, cacheTime, staleTime} = queryConfig;
    const defaultOptions = {
        retry,
        cacheTime,
        staleTime,
    };


    const useContents = (id) =>{
        const [GetContents]= useOperationMethod('GetContents');
        const [GetContent]= useOperationMethod('GetContent');

        return useQuery({
            queryKey: id? buildContentId(id): CONTENTS_KEY,
            queryFn: async () =>
               id? GetContent(id).then(({data}) => data):  GetContents().then(({data}) => data),
            ...defaultOptions,
        });
    }
    return {
        useContents
    }
}
